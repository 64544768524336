.q10__section {
    position: relative;
    isolation: isolate;
    // margin: 38px 0;

    &>.q10__section_slider {
        padding: 35px 0;
    }

    &>.content_wrapper {
        margin: 35px 0;
        // padding-top: 35px;
        @media (max-width: 1023px) {
            margin: 20px 0;
        }
    }
    &>.overlay + .content_wrapper {
        margin: 35px 0;
    }
}

.q10__section.join-children>div:not(.overlay) {
    padding: 0;

    padding-top: 0;
    // &:first-of-type {
    //     padding-top: 35px;
    // }
}

.q10__section.join-children {
    padding-top: 0px;

    @media (min-width: 1024px) {
        padding-top: 35px;
    }

    .content_wrapper {
        margin: 0;
    }
}

.q10__section.alternate {
    .overlay {
        background: var(--alternate_color);
        width: 110%;
        height: 100%;
        display: block;
        position: absolute;
        left: -15px;
        top: 0;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        margin: 0;

        @media (min-width: 1024px) {
            background-position: bottom center;
            width: 230%;
            left: -60%;
        }
    }

    &::before {
        content: "";
        background-image: url("/images/alternate_waves_even.svg");
        width: 110%;
        height: 15px;
        display: block;
        position: absolute;
        left: -15px;
        top: -5px;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;

        @media (min-width: 1024px) {
            background-position: bottom center;
            width: 230%;
            left: -60%;
            height: 43px;
        }

        svg {
            fill: transparent;
        }
    }

    &::after {
        content: "";
        background-image: url("/images/alternate_waves_odd.svg");
        width: 110%;
        height: 15px;
        display: block;
        position: absolute;
        bottom: -16px;
        left: -15px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        transform: rotate(180deg) scaleY(-1);

        @media (min-width: 1024px) {
            width: 230%;
            left: -60%;
            height: 43px;
            bottom: -43px;
        }
    }
}

.footer_overlay {
    width: 110%;
    height: 20px;
    display: block;
    position: absolute;
    left: -15px;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    z-index: -1;
}

.q10__block_title {
    color: var(--text_color);
    font-family: (var(--default-font));
    font-style: normal;
    line-height: normal;
    font-size: 20px;
    letter-spacing: 0.8px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 20px;
    width: fit-content;
    text-align: center;

    @media (min-width: 1024px) {
        font-size: 32px;
        letter-spacing: 1.28px;
        margin: unset;
        margin-bottom: 20px;

    }

    &::after {
        content: "";
        border-bottom: 3px solid #FA0000;
        bottom: -4px;
        width: 100%;
        left: 0px;
        position: absolute;
    }
}