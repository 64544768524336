.section_testimonial {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    border-radius: 10px;
    width: 100%;
    background-color: #fff;
    gap: 20px;

    @media (min-width: 1024px) {
        padding: 15px;
        max-width: 375px;
    }

    .section_testimonial_text {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .section_testimonial_title {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .section_testimonial_name {
                color: #333;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.72px;
            }

            .section_testimonial_subtitle {
                color: #333;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.72px;
            }
        }

        .section_testimonial_description {
            p {
                color: #333;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.56px;
            }
        }
    }

    .section_testimonial_image {
        border-radius: 10px;
        overflow: hidden;
        height: 230px;

        @media (min-width: 1024px) {
            // height: 269px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;


        }
    }
}