.q10__header {
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    z-index: 2;
    position: absolute;
    top: 0;
    max-width: 1320px;

    @media (min-width: 1024px) {
        position: absolute;
        background: none;
    }

    &__top {
        height: var(--header_mobile_height);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;

        @media (min-width: 1024px) {
            height: var(--header_desktop_height);
            padding: 0 40px;
        }

        @media (min-width: 1366px) {
            padding: 0;
        }

        .q10__waves {
            display: block;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;

            img {
                width: 100%;
                height: auto;
                position: absolute;
                top: -1px;
                left: 0;
            }

            @media (min-width: 1024px) {
                display: none;
            }
        }

        &__group {
            position: relative;
        }

        &__details {
            order: 3;
            display: flex;
        }

        &__lang {
            display: inline-block;
            color: var(--text_color);
            gap: 0.5rem;

            order: 3;

            @media (min-width: 1024px) {
                display: flex;
            }

            a {
                color: var(--text_color);
                font-weight: 100;
                font-family: var(--default-font);
            }

            &__name {
                position: relative;
                font-weight: 500;
                font-size: 16px;
                text-transform: uppercase;
                color: var(--text_color);
                @media (min-width: 1024px) {
                    color: rgba(51,51,51,.5);

                }
            }

            .dropdown-item:hover &__name {
                display: block;
            }

            &__icon {
                .icon {
                    width: 40px;
                    height: auto;
                    display: block;
                }

                &:hover~p {
                    display: block;
                }
            }

            .dropdown {
                position: relative;
                display: inline-block;
            }

            .dropdown-content {
                position: absolute;
                z-index: 5;
                top: 38px;
                left: 50%;
                transform: translateX(-50%);
                height: fit-content;
                padding: 10px 20px;
                display: none;
                width: 100px;
                border-radius: 10px;
                border: 1px solid var(--text_color);
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                &.show {
                    display: block;
                }
            }

            .dropdown-content a {
                display: flex;
                width: 100%;
                justify-content: center;
                // margin-left: 3px;

            }

            #selected {
                display: flex;
                padding: 10px;
                font-size: 20px;
                gap: 5px;
                cursor: pointer;

                .globe {
                    display: none;

                    @media (min-width: 1024px) {
                        display: block;
                    }
                }

                .angle_down {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg path {
                        stroke: var(--text_color);

                        @media (min-width: 1024px) {
                            stroke: #979797;
                        }
                    }
                }
            }

            .countryImage {
                display: inline;
                width: 35px;
                margin-right: 10px;
            }

            .countryText {
                display: flex;
                position: relative;
                color: var(--text_color);
                bottom: -2px;
                font-size: 16px;
                font-family: var(--default-font);
                text-transform: uppercase;

                @media (min-width: 1024px) {
                    color: rgba(51, 51, 51, 0.5);
                    font-size: 18px;
                    font-weight: 500;
                    justify-content: center;
                    align-items: center;
                }
            }

            .arrow_down {
                margin-left: 10px;
                width: 15px;
                height: 12px;
                position: relative;
                bottom: -11px;
            }

            .dropdown-item {
                display: flex;
            }
        }

        &__menu {
            display: none;

            @media (min-width: 1024px) {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
                order: 2;
            }

            @media (min-width: 1366px) {
                gap: 40px;
            }

            &>* {
                padding: 10px 5px;
                text-decoration: none;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                position: relative;

                .angle_right {
                    display: none;

                }

                &.item_selected>a {

                    position: relative;

                    @media (min-width: 1024px) {
                        &::after {
                            border-bottom: 3px solid #FA0000;
                            bottom: -4px;
                            content: "";
                            left: -2px;
                            position: absolute;
                            width: calc(100% + 4px);
                        }
                    }
                }

                .item_child>a {
                    img {
                        display: block;
                    }
                }

                a {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: var(--text_color);
                    line-height: normal;
                    letter-spacing: -0.0375em;
                    font-size: 22px;
                    font-size: calc(1rem + ((1vw - 7.68px) * 0.5208));
                    font-weight: 500;
                    font-family: var(--default-font);
                    text-transform: uppercase;

                    img {
                        display: none;
                    }
                }
            }

            .item_parent {
                display: inline-block;
            }

            .item_parent:hover .item_children {
                display: block;
            }

            .item_children {
                flex-direction: column;
                padding: 0 1rem;

                @media (min-width: 1024px) {
                    position: absolute;
                    top: 40px;
                    left: 50%;
                    transform: translateX(-50%);
                    height: fit-content;
                    padding: 20px 60px 20px 5px;
                    display: none;
                    width: fit-content;
                    // min-width: 310px;
                    border-radius: 10px;
                    border: 1px solid var(--text_color);
                    background: #FFF;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                }

                &>* {
                    display: flex;
                    padding-left: 15px;
                    flex-direction: column;
                    width: 100%;

                    .angle_right {
                        display: none;
                    }

                    &.item_selected > a > img{
                        display: block;
                    }

                    .arrow_right {
                        position: absolute;
                        right: 30px;
                    }

                    a {
                        color: rgba(51, 51, 51, 0.5);
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        font-size: 16px;
                        width: auto;
                        white-space: nowrap;
                    }
                }
            }

            .item_child>.item_children {
                position: relative;
                left: 0;
                transform: none;
                border: none;
                border-radius: 0;
                box-shadow: none;
                display: flex;
                width: 100%;
                top: 0;
                padding: 0;
            }

            .item_child>.item_children>.item_child>a >.arrow_right{
                right: -30px;
            } 

            .item_child>a {
                padding: 5px;
            }

            .item_search {

                img {
                    display: block;
                    height: 22px;
                    width: 22px;
                }
            }
        }

        &__box {
            margin-bottom: -7.5px;
            display: flex;
            padding-left: 15px;
            // max-width: 50%;
            // width: 125px;
            order: 2;

            @media (min-width: 1024px) {
                // width: 190px;
                height: 100%;
                padding-left: unset;
                margin-bottom: unset;
                justify-content: center;
                align-items: center;
                // padding-right: 3rem;
            }

            &__picture {
                display: block;
                width: 100%;
                height: auto;
                width: 125px;

                @media (min-width: 1024px) {
                    width: 190px;
                }

            }

        }

        &__burger_menu {
            padding-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            order: 3;

            .q10__header__top__burger {
                background-repeat: no-repeat;
                width: 30px;
                height: 30px;
            }

            @media (min-width: 1024px) {
                display: none;
            }
        }

        &__search {
            padding-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            order: 3;

            a {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 24px;
                    height: 24px;
                }
            }

            @media (min-width: 1024px) {
                display: none;
            }
        }
    }

    &__popup {
        overflow: hidden;
        display: none;
        left: 0;
        opacity: 1;
        right: 0;
        transition: opacity 0.25s ease-out;
        position: fixed;
        bottom: 0;
        // z-index: 100;
        top: 0;
        background-color: #fff;

        &__menu {
            overflow-y: auto;
            height: calc(100svh - 162px);
            border-top: 1px dashed #BBB;
            padding: 10px 0;

            @media (min-width: 1024px) {
                display: none;
            }

            &>* {
                padding: 0px 15px 0px 30px;
                text-decoration: none;
                margin: 0;
                display: flex;
                flex-direction: column;
                position: relative;

                .angle_right {
                    display: none;

                }

                .arrow_right {
                    position: absolute;
                    right: 15px;
                }

                &.item_selected {
                    img {
                        display: block;
                    }
                }

                a {
                    padding: 5px 0px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    color: #333333;
                    line-height: 1;
                    letter-spacing: -0.0375em;
                    font-size: 16px;
                    font-family: 'Roboto', sans-serif;
                    text-transform: uppercase;
                    min-height: 35px;
                }
            }

            .item_children {
                flex-direction: column;
                padding: 0 1rem;

                &>* {
                    display: flex;
                    flex-direction: column;

                    .angle_right {
                        display: none;
                    }

                    &.item_selected>a >img{
                        display: block;
                    }

                    a {
                        color: rgba(51, 51, 51, 0.5);
                        display: flex;
                        align-items: center;
                        width: 100%;
                    }
                }
            }
        }

        &__bottom {
            height: 93px;
            border-top: 1px dashed #BBB;
            padding: 10px 0;

            @media (min-width: 1024px) {
                display: none;
            }

            &>* {
                margin: 0;
                padding: 5px 15px 5px 30px;
                display: flex;

                .angle_right {
                    display: none;
                }

                &.item_selected {
                    img {
                        display: block;
                    }
                }

                a {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #333333;
                    line-height: 1;
                    letter-spacing: -0.0375em;
                    font-size: 16px;
                    font-family: var(--default-font);
                    text-transform: uppercase;
                }
            }
        }
    }
}

.q10_header_logout {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background: black;
    padding: 1rem;

    .button {
        text-decoration: none;
    }
}

.active {
    display: block !important;
    left: 0;
    opacity: 1;
    right: 0;
    transition: opacity 0.25s ease-out;
    position: relative;
    z-index: 3;
}

.active-lang {
    border-color: #dedfdf;
    border-style: solid;
    border-width: 0.1rem 0 0 0;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
}

.breadcrumbs {
    position: relative;
    padding: 20px 0;
    padding-bottom: 25px;
    overflow: auto;
    white-space: nowrap;
    @media (min-width: 1024px) {
        padding: 0;

    }

    &_slugs {

        margin: 0 auto;
        margin-top: 5px;

        ol {
            display: flex;
            align-items: center;
        }

        &_item {
            color: var(--text_color);
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0.56px;
            display: flex;

            @media (min-width: 1024px) {
                font-size: 18px;
                line-height: 28px;
                letter-spacing: 0.72px;
            }

            img {
                width: 18px;
                height: 18px;

                @media (min-width: 1024px) {
                    width: 30px;
                    height: 30px;
                }
            }
        }


        // @media (min-width: 1366px) {
        //     width: 1170px;
        // }
    }

    *>li {
        display: flex;
        align-items: center;

        svg {
            width: 18px;
            height: 18px;

            @media (min-width: 1024px) {
                width: 40px;
                height: 40px;
            }
        }
    }

    *>.active {
        color: #777;
    }
    &::-webkit-scrollbar {
        height: 5px;
    }
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(44, 78, 155, 0.50);
}

a {
    color: #333;
}