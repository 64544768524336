// @import "~bootstrap/scss/bootstrap";

// @import "variables";
@import "header";
@import "footer";
@import "ambient";
@import "hero";
@import "section";
@import "section_testimonial";
@import "section_testimonials";
@import "section_news";
@import "section_block_text_and_post_slider";
@import "section_block_slider_post_category";
@import "section_career";
@import "section_slider";
@import "section_video_popup";
@import "_variables";
@import "fonts";
@import "cookie";
@import "cookies";
@import "error_404";
@import "post_category";
@import "post";
@import "scroll_top";
@import "slick_carousel";
@import "vue_elements";

*,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition-property: background-color, color;
    transition-duration: 0.3s;
}

html {
    scroll-behavior: smooth;
    height: 100vh;
}

#app {
    background-color: var(--primary_color);
    min-height: 100vh;
    position: relative;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.background-app {
    background-color: white !important;
}

.single_block {
    & > :first-child {
        min-height: calc(100vh - var(--footer_height) - var(--header_height));
    }

    & > * {
        img {
            width: 100%;
        }
    }
}

.q10__header {
    + .q10__content {
        @media (min-width: 1024px) {
            margin-top: var(--header_desktop_height);
        }
    }
}

.q10__content {
    position: relative;
    margin: auto;
    padding: 0 15px;
    width: 100%;
    max-width: 1320px;
    flex-grow: 1;
    isolation: isolate;

    @media (min-width: 1024px) {
        padding: 0 40px;
    }

    @media (min-width: 1366px) {
        padding: 0;
    }

    &.with_ambient {
        margin-top: 0;
        // Pentru sectiunile cu ambient scoatem padding-bottom pentru a calcula corect distanta dintre footer si sectiune
    }

    &.no_ambient {
        margin-top: var(--header_mobile_height);
        
        @media (min-width: 1366px) {
            margin-top: var(--header_desktop_height);
        }
    }
}

.q10__section {
    padding-top: 5px;

    @media (min-width: 1024px) {
        padding-top: 40px;
    }
}

.breadcrumbs + .q10__section {
    padding-top: 0;

    &>.q10__section_slider {
        @media (max-width: 1023px) {
            padding: 20px 0;
        }
    }

    &>.content_wrapper {
        @media (max-width: 1023px) {
            margin: 20px 0;
        }
    }
}

a {
    text-decoration: none;
}

p {
    @media (min-width: 1024px) {
        font-size: 18px;
    }
}

.wysiwyg {
    p {
        margin-bottom: 1rem;
    }
    ul {
        margin-left: 1rem;
        margin-bottom: 1rem;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }

    * {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.3px;
        @media (min-width: 1024px) {
            font-size: 18px;
        }
    }
}
