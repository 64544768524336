.q10__section_slider {
    width: 100%;
    position: relative;

    .q10__section_slider_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;

        @media (min-width: 1024px) {
            flex-direction: row;
            justify-content: flex-start;
            gap: 50px;
        }

        .q10__section_slider_text {
            display: flex;
            flex-direction: column;

            @media (min-width: 1024px) {
                width: 50%;
            }

            .section_slider_title {
                color: var(--text_color);
                font-family: (var(--default-font));
                font-style: normal;
                line-height: normal;
                font-size: 20px;
                letter-spacing: 0.8px;
                margin: 0 auto;
                position: relative;
                margin-bottom: 20px;
                width: fit-content;
                text-align: center;

                @media (min-width: 1024px) {
                    font-size: 32px;
                    letter-spacing: 1.28px;
                    margin: unset;
                    margin-bottom: 20px;
                }

                &::after {
                    content: "";
                    border-bottom: 3px solid #FA0000;
                    bottom: -4px;
                    width: 100%;
                    left: 0px;
                    position: absolute;
                }
            }

            .section_slider_description {
                color: #333;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
            }
        }

        .content_wrapper {
            margin: 0 auto;
        }
    }
}