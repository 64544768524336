@import './node_modules/slick-carousel/slick/slick.scss';
@import './node_modules/slick-carousel/slick/slick-theme.scss';

.slick-slider {
    @media (min-width: 1024px) {
        padding: 0px 80px;
    }
}

.slick-track {
    display: flex;
    margin-top: 3px;
    margin-bottom: 3px;
}

.slick-slide {
    padding: 0 10px;
    height: inherit;
    & > div {
        height: 100%;
    }
}

.slick-dots {
    position: absolute;
    bottom: -45px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;

    li {
        border: 1px solid #2C4E9B;
        width: 18px;
        height: 18px;
        background-color: #ffffff;
        border-radius: 50%;
        position: relative;
        cursor: pointer;

        button {
            position: absolute;
            transition: all 0.3s ease-in-out;
            border: 1px solid transparent;
            background-color: #ffffff;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;

            &::before {
                content: '';
            }
        }
    }

    li.slick-active {
        button {
            transition: all 0.3s ease-in-out;
            background-color: #2C4E9B;
            opacity: 1;
            padding: 3px;
        }
    }
}


.slick-prev,
.slick-next {
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 150ms linear;
    position: absolute;
    width: 48px;
    height: 48px;
}

.slick-prev {
    left: 0;
}

.slick-next {
    right: 0;
}

.slick-prev:before,
.slick-next:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
}

.slick-prev::before {
    background: url("/images/angle_left_slider.svg") 0 0/100% no-repeat;
}

.slick-next::before {
    background: url("/images/angle_right_slider.svg") 0 0/100% no-repeat;
}