.section_career {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 10px;

    .section_career_text {
        &>div {
            position: relative;
            padding: 5px 10px;
        }

        &>div:nth-child(odd) {
            background: rgba(175, 175, 175, 0.15);
        }

        .section_career_details {
            color: #333;
            font-size: 16px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.64px;

            .section_career_description {
                font-weight: 400;
                display: inline;
            }
        }

    }

    .section_career_subtitle {
        font-weight: 500;
        display: inline;
    }
}