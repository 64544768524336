#sectionNews {
    // padding-top: 50px;

    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
        flex-wrap: wrap;
        flex-direction: row;
        gap: 30px;
    }

    .section_news {
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        border-radius: 10px;
        width: 100%;
        background-color: #fff;
        gap: 20px;
    
        @media (min-width: 1024px) {
            padding: 15px;
            max-width: 375px;
        }
    
        .section_news_text {
            display: flex;
            flex-direction: column;
    
            .section_news_title {
                margin-bottom: 20px;
                font-size: 18px;
                line-height: 21px;
                font-weight: 600;
            }
    
            .section_news_subtitle {
                color: #333;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.72px;
            }
    
            .section_news_description {
                p {
                    color: #333;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.64px;
                }
            }
        }
    
        .section_news_image {
            border-radius: 10px;
            overflow: hidden;
            height: 230px;
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    
            @media (min-width: 1024px) {
                // height: 269px;
            }
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
    
    
            }
        }
    }
}

