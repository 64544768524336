.q10__footer {
    width: 100%;
    padding: 12px;
    bottom: 0;
    // margin-top: 35px;
    position: relative;
    font-family: 'Roboto', sans-serif;
    background-color: var(--text_color);
    z-index: 1;

    &::before {
        content: "";
        background-image: url("/images/waves.svg");
        width: 103%;
        aspect-ratio: 29/1;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-100%);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        box-shadow: 0px 1px 0px 0px var(--text_color);
    }

    @media (min-width: 1024px) {
        // padding: 1.5rem 0.5rem;
        flex-direction: row;
        gap: 0rem;
        display: flex;
        justify-content: center;
        // margin-top: 56px;
        padding: 24px 40px;
    }

    @media (min-width: 1366px) {
        padding: 46px 0;
    }

    &__content {
        max-width: 1320px;

        @media (min-width: 1024px) {
            flex-direction: row;
            gap: 1rem;
            display: flex;
            justify-content: center;
            width: 100%;
            // max-width: 1320px;
            // margin: 0 auto;

            &>div {
                width: 100%;
            }
        }

        .q10__contact {

            @media (min-width: 1024px) {
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
            }

            &__title {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding-bottom: 10px;
                text-transform: uppercase;

                @media (min-width: 1024px) {
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            &__description {
                display: flex;
                gap: 11px;
                color: #FFF;
                font-weight: 300;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                margin-bottom: 24px;

                p {      
                    font-size: inherit;
                }

                @media (min-width: 1024px) {
                    font-size: 12px;
                    margin-bottom: unset;
                }

                @media (min-width: 1366px) {
                    font-size: 16px;
                }

                &>div {
                    width: 50%;
                }

                .q10__contact__address_and_social_media {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    .q10__contact__address {
                        color: var(--alternate_color);

                        .q10__contact__country {
                            width: 100%;
                            display: flex;
                            gap: 5px;

                            @media (min-width: 1024px) {
                                display: block;
                            }
                        }
                    }

                    .q10__contact__social_media {
                        gap: 10px;

                        img {
                            width: 25px;
                            height: 25px;

                            @media (min-width: 1024px) {
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }
                }

                .q10__contact__details {
                    color: var(--alternate_color);

                    .q10__contact__email {
                        a {
                            text-decoration: none;
                            color: inherit;
                        }
                    }
                }
            }
        }

        .q10__logos {
            display: inline-block;
            padding-right: 25px;

            @media (min-width: 1024px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-right: unset;
                ;
            }

            .q10__logo {
                @media (min-width: 1024px) {
                    padding: 10px;
                }

                img {
                    width: 110px;
                    height: auto;

                    @media (min-width: 1024px) {
                        width: 100%;
                    }
                }
            }
        }

        .q10__footer__nav {
            display: inline-block;

            @media (min-width: 1024px) {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                padding-left: 24px;
            }

            a {
                text-decoration: none;
                font-weight: bold;
            }

            .q10__logos {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .q10__logo {
                    img {
                        width: 100px;
                        height: auto;

                        @media (min-width: 1024px) {
                            width: 100%;
                        }
                    }
                }
            }

            .q10__policy {
                display: flex;
                gap: 10px;
                justify-content: center;

                @media (min-width: 1024px) {
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: unset;
                }

                .link {
                    color: rgb(255 255 255 / 75%);
                    text-align: center;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;

                    // &:hover {
                    //     color: #6f95f7;
                    // }

                    @media (min-width: 1024px) {
                        font-size: 12px;
                    }

                    @media (min-width: 1366px) {
                        font-size: 16px;
                    }
                }

                img {
                    display: none;
                }
            }

            .q10__copyright {
                color: rgb(255 255 255 / 75%);
                text-align: center;
                font-size: 10px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;

                @media (min-width: 1024px) {
                    font-size: 12px;
                    margin-top: 5px;
                }

                @media (min-width: 1366px) {
                    font-size: 16px;
                }
            }
        }

        // &__visit {
        //     color: #040505;
        //     text-align: center;
        // }
    }

    .q10__contact__social_media.mobile {
        display: flex;

        @media (min-width: 1024px) {
            display: none;
        }

        a {
            display: flex;
        }
    }

    .q10__contact__social_media.desktop {
        display: none;

        @media (min-width: 1024px) {
            display: flex;
            gap: 10px;
        }

        a {
            display: flex;

            img {
                width: 31px;
                height: 31px;
            }
        }
    }
}