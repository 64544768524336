.cookie-consent {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .07);
    bottom: 0;
    left: 0;
    margin-bottom: 1rem;
    margin-left: 1rem;
    padding: 1em;
    position: fixed;
    // text-align: center;
    font-family: inherit;
    width: 100%;
    max-width: 435px;
    z-index: 99;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        margin-bottom: 0;
        margin-left: 0;
    }

    .cookie-consent-logo {
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    .cookie-consent-content {
        display: none;
        margin: 5px 0;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .cookie-consent-message {
            color: var(--text_color);
            font-size: 16px;
            font-family: var(--default-font);

            a {
                color: var(--text_color);
                text-decoration: underline;
            }
        }

        .cookie-consent-btns {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            gap: .5rem;

            @media (max-width: 768px) {
                flex-direction: column;
            }

            .cookie-consent-agree {
                background-color: var(--background_color);
                border: 1px solid var(--background_color);
                border-radius: 5px;
                box-shadow: 0 2px 5px rgba(217, 142, 0, .15);
                font-weight: 700;
                color: #fff;
                padding: 1rem 3.5rem;
                font-size: 16px;
                outline: none;
                transition: all .3s ease-in-out;
                cursor: pointer;

                &:hover {
                    background-color: #000;
                    color: #fff;
                }
            }

            .cookie-consent-disagree {
                background-color: var(--secondary_color);
                border: 1px solid var(--secondary_color);
                border-radius: 5px;
                box-shadow: 0 2px 5px rgba(217, 142, 0, .15);
                color: var(--text_color);
                font-weight: 700;
                padding: 1rem 3.5rem;
                font-size: 16px;
                outline: none;
                transition: all .3s ease-in-out;
                cursor: pointer;

                &:hover {
                    background-color: #000;
                    color: #fff;
                }
            }

            .cookie-consent-read {
                font-size: 16px;
                color: #0eaedf;
                text-decoration: none;
                transition: all .3s ease-in-out;

                &:hover {
                    color: #000;
                    text-decoration: underline;
                }
            }
        }
    }
}