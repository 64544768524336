#sectionTestimonials {
    // padding-top: 50px;

    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
        padding-top: 50px;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 30px;
    }
}