.q10__ambient {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: var(--ambient_mobile_height);
    // position: sticky;
    // top: 0;
    // z-index: 10;

    @media (min-width: 1024px) {
        align-items: center;
        height: var(--ambient_desktop_height);
    }

    &_polygons {
        display: none;

        @media (min-width: 1024px) {
            display: block;
            position: absolute;
            left: 0;
            background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.75) 20%, rgba(255, 255, 255, 0.25) 65%, rgba(255, 255, 255, 0) 100%);
            top: 0;
            width: 100%;
            z-index: 1;
        }

        img {
            width: 100%;
            height: 150px;
            object-fit: cover;
            object-position: top;
        }

        &_mobile {
            display: flex;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 1;

            @media (min-width: 1024px) {
                display: none;
            }
        }
    }

    &_image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &_waves {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -1px;
        display: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media (min-width: 1024px) {
            display: flex;
        }
    }

    &_waves_mobile {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -1px;
        display: flex;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media (min-width: 1024px) {
            display: none;
        }
    }
}